<template>
  <div class="merchantadd-container position-relative">
    <div>
      <el-form
        label-width="120px"
        :model="merchantList"
        ref="addForm"
        :rules="rules"
        :inline="true"
        :hide-required-asterisk="true"
      >
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="售后单号" prop="as_no">
                <div>{{ merchantList.as_no }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单号" prop="order_no">
                <div>{{ merchantList.order_no }}</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="客户姓名" prop="merchantPhone">
                <div v-if="merchantList.order && merchantList.order.member">
                  {{ merchantList.order.member.fullname }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户电话" prop="storeType">
                <div v-if="merchantList.order && merchantList.order.member">
                  {{ merchantList.order.member.mobile }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="城市仓" prop="warehouseId">
                <div
                  v-if="
                    merchantList.order &&
                    merchantList.order.store_delivery &&
                    merchantList.order.store_delivery.logistics
                  "
                >
                  {{ merchantList.order.store_delivery.logistics.name }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="自提点信息" prop="carLicense">
                <div
                  v-if="merchantList.order && merchantList.order.store_delivery"
                >
                  {{ merchantList.order.store_delivery.name }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="auto-table-flex mb-30">
      <el-table
        :data="goodsData"
        v-loading="loading"
        :border="true"
        :span-method="arraySpanMethod"
        :show-summary="false"
        ref="goodsTable"
        :summary-method="goodsCount"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <!-- <el-table-column type="index" width="80" align="center" label="序号">
                    </el-table-column> -->
        <el-table-column
          prop="goodsName"
          width="130"
          align="center"
          label="主图"
        >
          <template slot-scope="scope">
            <div
              class="d-flex a-center"
              v-if="scope.$index == 1 || scope.$index % 2 !== 0"
            >
              <div class="d-flex a-center mr10">
                <div>商品金额：</div>
                <div class="input-width">
                  <el-input
                    v-model="scope.row.refund_amt"
                    :disabled="detailDisabled"
                    @blur="
                      surchargeChange(
                        scope.$index,
                        scope.row.refund_amt,
                        'refund_amt'
                      )
                    "
                    @input="
                      surchargeChange(
                        scope.$index,
                        scope.row.refund_amt,
                        'refund_amt'
                      )
                    "
                    placeholder="输入商品金额"
                    clearable
                    ><div
                      slot="suffix"
                      style="
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #333333;
                      "
                    >
                      元
                    </div></el-input
                  >
                </div>
              </div>
              <!-- <div class="d-flex a-center mr40">
                <div>其他商品金额：</div>
                <div class="input-width">
                  <el-input v-model="scope.row.freight_amt" :disabled="detailDisabled"
                    @blur="surchargeChange(scope.$index, scope.row.freight_amt, 'freight_amt')"
                    @input="surchargeChange(scope.$index, scope.row.freight_amt, 'freight_amt')" placeholder="输入其他商品金额"
                    clearable></el-input>
                </div>
              </div> -->
              <div class="d-flex a-center mr10">
                <div>运费：</div>
                <div class="input-width">
                  <el-input
                    v-model="scope.row.freight_amt"
                    :disabled="detailDisabled"
                    @blur="surchargeChange(scope.$index)"
                    @input="surchargeChange(scope.$index)"
                    placeholder="输入运费"
                    clearable
                    ><div
                      slot="suffix"
                      style="
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #333333;
                      "
                    >
                      元
                    </div></el-input
                  >
                </div>
              </div>
              <div class="d-flex a-center mr10">
                <div>服务费：</div>
                <div class="input-width">
                  <el-input
                    v-model="scope.row.purchase_amt"
                    :disabled="detailDisabled"
                    @blur="surchargeChange(scope.$index)"
                    @input="surchargeChange(scope.$index)"
                    placeholder="输入服务费"
                    clearable
                    ><div
                      slot="suffix"
                      style="
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #333333;
                      "
                    >
                      元
                    </div></el-input
                  >
                </div>
              </div>
              <div class="d-flex a-center mr10">
                <div>金融手续费：</div>
                <div class="input-width">
                  <el-input
                    v-model="scope.row.finance_amt"
                    :disabled="detailDisabled"
                    @blur="surchargeChange(scope.$index)"
                    @input="surchargeChange(scope.$index)"
                    placeholder="输入金融手续费"
                    clearable
                    ><div
                      slot="suffix"
                      style="
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #333333;
                      "
                    >
                      元
                    </div></el-input
                  >
                </div>
              </div>
              <div class="d-flex a-center">
                <div>合计：</div>
                <div class="input-width">
                  <el-input
                    :disabled="true"
                    v-model="scope.row.all"
                    placeholder="合计金额"
                    clearable
                    ><div
                      slot="suffix"
                      style="
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #333333;
                      "
                    >
                      元
                    </div></el-input
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <template v-if="scope.row.product">
                <!-- <img @click.stop="getGlass(scope.row.product.kv)" class="kv pointer" :src="scope.row.product.kv" alt=""> -->
                <el-image
                  class="kv pointer"
                  :src="scope.row.product.kv"
                  :preview-src-list="[scope.row.product.kv]"
                >
                </el-image>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="商品信息">
          <template slot-scope="scope">
            <div v-if="scope.row.product" class="d-flex flex-column">
              <div class="border-b pb">
                商品名称：{{ $empty.empty(scope.row.product.title) }} ({{
                  $empty.empty(scope.row.product.sku_code)
                }})
              </div>
              <div class="border-b py">
                商品价格：{{
                  tool.toDecimal2(
                    (scope.row.product.price * 10000) / 100 / 10000
                  )
                }}元
              </div>
              <div class="border-b py">
                毛重：{{ $empty.empty(scope.row.product.gross_weight) }}斤
              </div>
              <div class="border-b py">
                毛单价：{{
                  tool.toDecimal2(
                    (((scope.row.product.price * 1000) /
                      scope.row.product.gross_weight) *
                      1000) /
                      100 /
                      1000000
                  )
                }}元
              </div>
              <div class="pt">
                购买数量：{{ $empty.empty(scope.row.sale_qty) }}个
              </div>
              <div class="pt">
                售后条件：{{ $empty.empty(scope.row.product.as_cond) }}
              </div>
            </div>
            <div v-else>—</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          align="center"
          width="150"
          label="费用信息(元)"
        >
          <template slot-scope="scope">
            <div class="d-flex flex-column" v-if="scope.row.sale_freight_amt">
              <div class="border-b pb">
                运费：{{ scope.row.sale_freight_amt }}
              </div>
              <div class="border-b py">
                服务费：{{ scope.row.sale_purchase_amt }}
              </div>
              <div class="pt">金融手续费：{{ scope.row.sale_finance_amt }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="price" align="center" label="商品价格(元)">
          <template slot-scope="scope">
            <span v-if="scope.row.product">{{ tool.toDecimal2(scope.row.product.price * 10000 / 100 / 10000) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="id_card" align="center" label="购买数量">
          <template slot-scope="scope">
            <span v-if="scope.row.product">{{ $empty.empty(scope.row.product.title) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="freight_amt" align="center" label="运费(元)">
          <template slot-scope="scope">
            <span v-if="scope.row.freight_amt">{{ tool.toDecimal2(scope.row.freight_amt * 10000 / 100 / 10000) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="purchase_amt" align="center" label="服务费(元)">
          <template slot-scope="scope">
            <span v-if="scope.row.purchase_amt">{{ tool.toDecimal2(scope.row.purchase_amt * 10000 / 100 / 10000) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="finance_amt" align="center" label="金融手续费(元)">
          <template slot-scope="scope">
            <span v-if="scope.row.finance_amt">{{ tool.toDecimal2(scope.row.finance_amt * 10000 / 100 / 10000) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="reason" align="center" label="售后原因">
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row.reason) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="product_weight"
          width="110"
          align="center"
          label="报损重量(斤)"
        >
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row.product_weight) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="desc" align="center" label="问题描述">
          <template slot-scope="scope">
            <span>{{ scope.row.desc }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="attach_pic" label="证据">
          <template
            slot-scope="scope"
            v-if="scope.row.attach_pic || scope.row.attach_video"
          >
            <!-- <img v-if="scope.row.attach_pic" v-for="(img, idx) in scope.row.attach_pic.split(',')" :key="idx"
              @click.stop="getGlass(img)" class="kv pointer mr10" :src="img" alt=""> -->
            <el-image
              class="kv pointer mr10"
              v-if="scope.row.attach_pic"
              v-for="(img, idx) in scope.row.attach_pic.split(',')"
              :key="idx"
              :src="img"
              :preview-src-list="[img]"
            >
            </el-image>
            <video
              v-if="scope.row.attach_video"
              ref="videoPlayer"
              :src="scope.row.attach_video"
              :autoplay="false"
              :controls="true"
              class="video pointer"
            ></video>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        :show-header="false"
        :data="sumData"
        :border="true"
        ref="goodsTable"
        :span-method="arraySpanMethod1"
        :cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="key" align="center"></el-table-column>
        <el-table-column prop="total"></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </div>
    <div class="d-flex a-center remark-wrapper" style="padding-bottom: 50x">
      <div style="white-space: nowrap">备注：</div>
      <el-input
        type="textarea"
        :disabled="detailDisabled"
        :rows="2"
        placeholder="请输入备注"
        v-model="merchantList.remark"
      >
      </el-input>
    </div>
    <div class="fy1 fy2" style="position: fixed; bottom: 0; right: 0">
      <el-button @click="$router.back()">返回</el-button>
      <el-button type="success" v-if="state == 0" @click="sureConfirm"
        >确定</el-button
      >
      <span v-if="state == 0" style="color: #e66f34; margin-left: 10px"
        >注：请谨慎填写报损金额，确认后将直接退款给客户！！！</span
      >
    </div>
    <div v-show="showImage" class="shadow" @click="showImage = false"></div>
    <div v-show="showImage" class="bigImage">
      <img :src="bigImage" alt="" />
      <i class="el-icon el-icon-close bigClose" @click="showImage = false"></i>
    </div>
  </div>
</template>

<script>
import { BASE } from "@/api";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      bigImage: "",
      all_money: 0,
      showImage: false,
      merchantList: {
        merchantId: "",
        merchantPhone: "",
        storeType: "",
        warehouseId: "",
        carLicense: "",
        type: 1,
        areaIds: "",
        carryTeamId: "",
      },
      order: "",
      isDetail: "",
      areaName: "",
      orderSn: "",
      refundAmount: "",
      totalAmount: "",
      areaOptions: [],
      moneyall: 0,
      moneycountall: 0,
      options: false,
      savebtn: false,
      businessList: [],
      business: [],
      categoryData: [],
      carryTeamList: [],
      surchargeList: [],
      addDisabled: false,
      id: "",
      goodsWeight: "",
      goodsAmount: "",
      state: "",
      word: "",
      attachAmount: "",
      paystate: "",
      remark: [],
      spc: [],
      cass: [],
      spcDisable: [],
      merchantNameList: [],
      warehouseNameList: [],
      goodsData: [
        //商品信息
      ],
      sumData: [
        {
          key: "合计：",
          total: 0,
        },
      ],
      originData: [],
      moneyData: [],
      goodsNameData: [],
      loading: true,
      detailDisabled: false,
      rules: {
        merchantId: [
          {
            required: true,
            message: "请选择商户名称",
            trigger: ["blur", "change"],
          },
        ],
        merchantPhone: [
          {
            required: true,
            message: "请输入商户电话",
            trigger: ["blur", "change"],
          },
          {
            validator: function (rule, value, callback) {
              if (/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value) == false) {
                callback(new Error("该手机号不符合规范"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        storeType: [
          {
            required: true,
            message: "请选择仓库类别",
            trigger: ["blur", "change"],
          },
        ],
        warehouseId: [
          {
            required: true,
            message: "请选择库位号",
            trigger: ["blur", "change"],
          },
        ],
        carLicense: [
          {
            required: true,
            message: "请输入车牌号",
            trigger: ["blur", "change"],
          },
        ],
        areaIds: [
          {
            required: true,
            message: "请选择入库货物来源",
            trigger: ["blur", "change"],
          },
        ],
        carryTeamId: [
          {
            required: true,
            message: "请选择操作员",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.state = this.$route.query.state;
      this.getDetail();
    }
    if (this.$route.query && this.$route.query.word) {
      this.word = this.$route.query.word;
    }
    if (this.$route.query && this.$route.query.word) {
      this.detailDisabled = true;
    }
  },
  methods: {
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 !== 0) {
        return [1, 10];
      }
    },
    arraySpanMethod1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 0) {
        return [2, 7];
      }
    },
    surchargeChange(idx) {
      this.tool.debounce(() => {
        console.log(this.goodsData[idx]);
        let money = 0;
        this.all_money = 0;
        this.goodsData[idx]["all"] = this.tool.toDecimal2(
          (Number(this.goodsData[idx]["refund_amt"]) * 1000 +
            Number(this.goodsData[idx]["freight_amt"]) * 1000 +
            Number(this.goodsData[idx]["purchase_amt"]) * 1000 +
            Number(this.goodsData[idx]["finance_amt"]) * 1000) /
            1000
        );
        this.goodsData.forEach((item, index) => {
          this.all_money = this.tool.toDecimal2(
            (Number(this.all_money) * 1000 + Number(item.all) * 1000) / 1000
          );
        });
        this.sumData[0].total = this.all_money + "元";
      });
    },
    sureConfirm() {
      let form = [];
      for (let i = 0; i < this.goodsData.length; i++) {
        if (i % 2 != 0) {
          if (
            this.goodsData[i].refund_amt === "" ||
            this.goodsData[i].refund_amt < 0
          ) {
            this.tool.message(`请正确输入第${i}个商品金额`, "error");
            return;
          } else if (
            this.goodsData[i].freight_amt === "" ||
            this.goodsData[i].freight_amt < 0
          ) {
            this.tool.message(`请正确输入第${i}个运费`, "error");
            return;
          } else if (
            this.goodsData[i].purchase_amt === "" ||
            this.goodsData[i].purchase_amt < 0
          ) {
            this.tool.message(`请正确输入第${i}个服务费`, "error");
            return;
          } else if (
            this.goodsData[i].finance_amt === "" ||
            this.goodsData[i].finance_amt < 0
          ) {
            this.tool.message(`请正确输入第${i}个金融手续费`, "error");
            return;
          }
          form.push({
            detail_id: this.goodsData[i].id,
            refund_amt: this.goodsData[i].refund_amt,
            freight_amt: this.goodsData[i].freight_amt,
            purchase_amt: this.goodsData[i].purchase_amt,
            finance_amt: this.goodsData[i].finance_amt,
          });
        }
      }
      // this.goodsData.forEach((item, index) => {

      // })
      console.log(form);
      console.log(this.id);
      console.log(this.merchantList.remark);
      if (!this.merchantList.remark) {
        this.tool.message("请输入备注", "error");
        return;
      }
      this.$confirm("确定审核吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let load = Loading.service({ fullscreen: false });
          this.tool.debounce(() => {
            this.$api.general
              .updateAftersale({
                id: this.id,
                detail: form,
                remark: this.merchantList.remark,
              })
              .then((res) => {
                load.close();
                this.tool.message("审核成功", "success");
                setTimeout(() => {
                  this.$router.back();
                }, 800);
              })
              .catch((error) => {
                load.close();
              });
          });
        })
        .catch(() => {});
    },
    // 获取详情
    getDetail() {
      this.$api.general.aftersaleDetail({ id: this.id }).then((res) => {
        this.merchantList = res.data;
        let data = this.tool.DeepCopy([], res.data.aftersale_detail);
        let idx = 0;
        res.data.aftersale_detail.forEach((item, index) => {
          idx++;
          data.splice(index + idx, 0, { id: item.id });
        });
        // this.goodsData = res.data.aftersale_detail
        console.log(data);
        if (this.word == "detail") {
          for (let i = 0; i < data.length; i++) {
            if (i > 0 && i % 2 != 0) {
              data[i - 1].refund_amt = this.tool.toDecimal2(
                (data[i - 1].refund_amt * 100) / 100 / 100
              );
              data[i - 1].freight_amt = this.tool.toDecimal2(
                (data[i - 1].freight_amt * 100) / 100 / 100
              );
              data[i - 1].purchase_amt = this.tool.toDecimal2(
                (data[i - 1].purchase_amt * 100) / 100 / 100
              );
              data[i - 1].finance_amt = this.tool.toDecimal2(
                (data[i - 1].finance_amt * 100) / 100 / 100
              );
              data[i].refund_amt = data[i - 1].refund_amt;
              data[i].freight_amt = data[i - 1].freight_amt;
              data[i].purchase_amt = data[i - 1].purchase_amt;
              data[i].finance_amt = data[i - 1].finance_amt;
              data[i].all = this.tool.toDecimal2(
                (Number(data[i - 1].refund_amt) * 1000 +
                  Number(data[i - 1].freight_amt) * 1000 +
                  Number(data[i - 1].purchase_amt) * 1000 +
                  Number(data[i - 1].finance_amt) * 1000) /
                  1000
              );
              this.all_money = this.tool.toDecimal2(
                (Number(this.all_money) * 1000 + Number(data[i].all) * 1000) /
                  1000
              );
            }
          }
          this.sumData[0].total = this.all_money + "元";
        } else {
          for (let i = 0; i < data.length; i++) {
            data[i].refund_amt = 0;
            data[i].freight_amt = 0;
            data[i].purchase_amt = 0;
            data[i].finance_amt = 0;
            data[i].all = 0;
          }
          this.sumData[0].total = 0;
        }
        console.log();
        this.goodsData = data;
        this.loading = false;
      });
    },
    // 商品信息合计方法
    goodsCount(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：" + this.all_money;
          return;
        }
      });
      this.sumData[0] = { total: sums };
      return sums;
    },
  },
};
</script>

<style lang="less">
.merchantadd-container {
  .kv {
    width: 100px;
    height: 100px;
  }
  .fy2 {
    left: 250px;
  }
  .pt {
    padding-top: 5px;
  }
  .py {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pb {
    padding-bottom: 5px;
  }
  .border-b {
    border-bottom: 1px solid #ebeef5;
  }
  .video {
    width: 230px;
  }

  .el-input.is-disabled .el-input__inner,
  .el-textarea.is-disabled .el-textarea__inner {
    color: #333333;
  }

  .remark-wrapper {
    padding: 20px 60px;
    border: 1px solid #dbe0e4;
  }

  .input-width {
    width: 200px;
  }

  .sale-font {
    font-size: 15px;
  }

  padding: 10px 10px 60px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #13AE67;
  //   font-size: 20px;
  // }
}
</style>
